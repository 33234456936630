// This file has to be in sync with colors.js.
$color-neutral-999: #000000;
$color-neutral-900: #1b1a21;
$color-neutral-800: #4c4a57;
$color-neutral-700: #777582;
$color-neutral-500: #c2c1c7;
$color-neutral-300: #e6e5ea;
$color-neutral-200: #f3f3f5;
$color-neutral-100: #fafafa;
$color-neutral-0: #ffffff;

$color-red-900: #c41337;
$color-red-800: #f4346f;
$color-red-600: #f85b8a;
$color-red-400: #ffc2d4;
$color-red-200: #ffebf2;

$color-yellow-900: #9e6d00;
$color-yellow-800: #ffb000;
$color-yellow-600: #ffcf64;
$color-yellow-400: #ffe694;
$color-yellow-200: #fffade;

$color-green-900: #00873f;
$color-green-800: #00ca68;
$color-green-600: #00e676;
$color-green-400: #a8ffce;
$color-green-200: #e6fcee;

$color-cyan-900: #00408f;
$color-cyan-800: #0081d7;
$color-cyan-600: #00f5fe;
$color-cyan-400: #a6fcff;
$color-cyan-200: #d9feff;

$color-indigo-900: #1e0a78;
$color-indigo-800: #311b92;
$color-indigo-600: #4f34c7;
$color-indigo-400: #c0b7eb;
$color-indigo-200: #e8e6f5;
$color-indigo-100: #f3f2fc;

$color-purple-900: #8800cc;
$color-purple-800: #aa00ff;
$color-purple-600: #c550ff;
$color-purple-400: #ecc7ff;
$color-purple-200: #f9edff;

$color-magenta-900: #b300b3;
$color-magenta-800: #ff00ff;
$color-magenta-600: #ff7bff;
$color-magenta-400: #ffbfff;
$color-magenta-200: #ffebff;

$color-orange: #ff7438;

$box-shadow-small-neg-y: 0px -1px 4px rgba($color-neutral-900, 0.25);
$box-shadow-small-pos-y: 0px 1px 4px rgba($color-neutral-900, 0.25);
$box-shadow-medium: 0px 2px 6px rgba($color-neutral-900, 0.25);
$box-shadow-large: 0px 6px 16px rgba($color-neutral-900, 0.25);

// MemSQL text colors
$color-text-1: $color-neutral-900;
$color-text-2: $color-neutral-800;
$color-text-3: $color-neutral-700;

// MemSQL contextual colors
$success-background: $color-green-200;
$success-text: $color-green-900;
$alert-background: $color-yellow-200;
$alert-text: $color-yellow-900;
$error-background: $color-red-200;
$error-text: $color-red-900;
$info-background: $color-indigo-100;
$info-text: $color-indigo-600;
$progress-background: $color-indigo-100;
$progress-text: $color-indigo-600;
