@mixin text-base {
    line-height: 130%;
}

@mixin text-headline {
    @include text-base;
    font-weight: $font-weight-bold;
    font-size: 55px;
}

@mixin text-title {
    @include text-base;
    font-weight: $font-weight-bold;
    font-size: 34px;
}

@mixin text-subheader {
    @include text-base;
    font-weight: $font-weight-bold;
    font-size: 22px;
}

@mixin text-subheader-small {
    @include text-base;
    font-weight: $font-weight-bold;
    font-size: 16px;
}

@mixin text-body-2 {
    @include text-base;
    font-weight: $font-weight-regular;
    font-size: 18px;
}

@mixin text-body {
    @include text-base;
    font-weight: $font-weight-regular;
    font-size: 16px;
}

@mixin text-table-header {
    @include text-base;
    font-weight: $font-weight-black;
    font-size: 14px;
}

@mixin text-button {
    @include text-base;
    font-weight: $font-weight-black;
    font-size: 14px;
}

@mixin text-label {
    @include text-base;
    font-weight: $font-weight-bold;
    font-size: 13px;
}

@mixin text-caption {
    @include text-base;
    font-weight: $font-weight-regular;
    font-size: 12px;
}

@mixin text-monospaced {
    font-family: Inconsolata, monospace;
}
