@import "scss/lib/index.scss";

.unsupported-browser-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

    margin: 0;
    background: #f7f7f7;

    > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-top: 50px;
        background: $color-neutral-0;
        max-width: 500px;
        padding: 75px 30px;

        border: 1px solid $color-neutral-500;
        border-radius: 6px;

        @include breakpoint(zero, small) {
            max-width: 75%;
            padding: 30px 10px;
        }

        > .title {
            margin-top: 20px;

            @include text-title;

            @include breakpoint(zero, small) {
                font-size: 24px;
            }
        }

        > .description {
            margin-top: 10px;

            font-size: 18px;
            color: $color-neutral-800;
            line-height: 150%;
        }

        > .actions {
            display: flex;
            width: 80%;
            justify-content: space-between;

            margin-top: 40px;
        }
    }
}
