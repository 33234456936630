$fa-font-path: "/fonts" !default;

$header-height: 64px;
$toolbar-height: $header-height;

$tutorial-content-padding: 16px;
$tutorial-container-width: 320px;
$tutorial-bottom-spacing: 40px;

$breakpoints: (
    zero: 0px,
    xsmall: 650px,
    small: 900px,
    medium: 1200px,
    medium-large: 1400px,
    large: 1600px,
    xlarge: 2000px,
);

/* this has to be in sync with util/z-index.js */
$zindex-base: 0;
$zindex-menu: 500;
$zindex-banner: 800;
$zindex-toolbar: 900;
$zindex-bottom-panel: 900;
$zindex-header: 1000;
$zindex-drag-handle: 2000;
$zindex-scrollbar: 2100;
$zindex-dropdown: 2200;
$zindex-tutorial: 2800;
$zindex-modal: 3000;
$zindex-toast: 3200;
$zindex-overlay: 3500;
$zindex-tooltip: 4000;

$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$card-border-radius: 4px;

/* this has to be in sync with WIDTH_WIDE in sidebar.tsx */
$sidebar-active-bg: rgba(249, 237, 255, 0.15);
$sidebar-hover-bg: rgba(249, 237, 255, 0.05);
$sidebar-width: 205px;
